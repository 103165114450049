import {
  FETCHING_BURNOUT_BLOCKS,
  BURNOUT_BLOCKS_FETCH_SUCCESS,
  BURNOUT_BLOCKS_FETCH_FAILURE,
  SELECT_BURNOUT_BLOCK,
  RESET_BURNOUT_BLOCK
} from "../_actions/_types.actions";

// let currentBurnoutBlock = JSON.parse(localStorage.getItem('currentBlock'));
let currentBurnoutBlock = {id: 0};
const initialState = {
  burnoutBlocks: [],
  fetchingBurnoutBlocks: false,
  burnoutBlocksFetchError: null,
  currentBurnoutBlock
};

export default function burnout_blocks(state = initialState, action) {
  switch (action.type) {
    case FETCHING_BURNOUT_BLOCKS:
      return Object.assign({}, state,{fetchingBurnoutBlocks: action.status});
    case BURNOUT_BLOCKS_FETCH_SUCCESS:
      return Object.assign({}, state, {burnoutBlocks: action.data, burnoutBlocksFetchError: null});
    case BURNOUT_BLOCKS_FETCH_FAILURE:
      return Object.assign({}, state, {burnoutBlocksFetchError: action.error});
    case SELECT_BURNOUT_BLOCK:
      return Object.assign({}, state, {currentBurnoutBlock: action.block});
    case RESET_BURNOUT_BLOCK:
      return  Object.assign({}, state, {currentBurnoutBlock: null});
    default:
      return state;
  }
}

export const blockIds = burnout_blocks => {
  if (burnout_blocks && burnout_blocks.burnoutBlocks) {
    return burnout_blocks.burnoutBlocks.map( b => b.id)
  } else {
    return []
  }
}

export const currentBlockStatus = burnout_blocks => {
  if (burnout_blocks && burnout_blocks.currentBurnoutBlock && burnout_blocks.currentBurnoutBlock.status) {
    return burnout_blocks.currentBurnoutBlock.status
  } else {
    return null;
  }
};

// for ANA, we use specialty as "Years in Nursing" and for St Jude, we use it as "Shift"
export const getSpecialtyFilterName = burnout_blocks => {
  if (burnout_blocks && burnout_blocks.currentBurnoutBlock && burnout_blocks.currentBurnoutBlock.group_id) {
    if (burnout_blocks.currentBurnoutBlock.group_id === parseInt(process.env.REACT_APP_ST_JUDE_GROUP_ID)) {
      return "Shift"
    } else if (
      burnout_blocks.currentBurnoutBlock.group_id === parseInt(process.env.REACT_APP_ANA_GROUP_ID) ||
      burnout_blocks.currentBurnoutBlock.group_id === 47 // Wyoming School Nurses
    ) {
      return "Years in Nursing"
    } else {
      return "Specialty"
    }
  } else {
    return 'Specialty'
  }
}


export const getLicenseTypeFilterName = burnout_blocks => {
  if (burnout_blocks && burnout_blocks.currentBurnoutBlock && burnout_blocks.currentBurnoutBlock.group_id) {
    if (burnout_blocks.currentBurnoutBlock.group_id === parseInt(process.env.REACT_APP_ST_JUDE_GROUP_ID)) {
      return "Role"
    } else {
      return "License Type"
    }
  } else {
    return "License Type"
  }
}
