import {
  FETCHING_EC_CONTENT,
  EC_CONTENT_FETCH_FAILURE,
  EC_CONTENT_FETCH_SUCCESS,
  FETCHING_EC_CATEGORIES,
  EC_CATEGORIES_FETCH_FAILURE,
  EC_CATEGORIES_FETCH_SUCCESS,
  FETCHING_EC_SECTIONS,
  EC_SECTIONS_FETCH_SUCCESS,
  EC_SECTIONS_FETCH_FAILURE,
  FETCHING_EC_CONTENT_TO_SECTIONS,
  EC_CONTENT_TO_SECTIONS_FETCH_SUCCESS,
  EC_CONTENT_TO_SECTIONS_FETCH_FAILURE,
  CLAIMING_CME_CREDIT,
  CLAIM_CME_CREDIT_FAILURE,
  CLAIM_CME_CREDIT_SUCCESS,
  FETCHING_CME_CREDIT,
  CME_CREDIT_FETCH_FAILURE,
  CME_CREDIT_FETCH_SUCCESS,
  CLAIMING_CONTACT_HOURS,
  CLAIM_CONTACT_HOURS_SUCCESS,
  CLAIM_CONTACT_HOURS_FAILURE,
  FETCHING_CONTACT_HOURS,
  CONTACT_HOURS_FETCH_SUCCESS,
  CONTACT_HOURS_FETCH_FAILURE,
  TIME_WATCHED_FETCH_FAILURE,
  TIME_WATCHED_FETCH_SUCCESS,
  FETCHING_TIME_WATCHED,
  UPDATE_TIME_WATCHED,
  UPDATE_TOAST_NOTIFICATION,
  SET_SORT_VALUE
} from "../_actions/_types.actions";

const initialState = {
  fetchingEcContent: false,
  ecContentFetchError: null,
  content: [],

  fetchingEcSections: false,
  ecSectionsFetchError: null,
  sections: [],

  fetchingEcContentToSections: false,
  ecContentToSectionsFetchError: null,
  contentToSections: [],

  fetchingEcCategories: false,
  ecCategoriesFetchError: null,
  categories: [],

  fetchingCmeCredits: false,
  cmeCreditFetchError: null,
  // claimedContent and claimedContactHoursContent needs to be null instead of an empty array
  // for the system to know to pull it from the database in ec_loading_modal.jsx
  claimedContent: null,
  claimingCredit: false,
  claimCreditError: null,

  fetchingCreditHours: false,
  contactHoursFetchError: null,
  claimedContactHourContent: null,
  claimingContactHour: false,
  claimContactHourError: null,

  timeWatched: {},
  fetchingTimeWatched: false,
  timeWatchedError: null,

  toastNotificationStatus: false,
  toastNotificationData: {},

  sortValue: "Suggested for Me",
  totalVideos: 0
};

//This will be used if the EC content has not finished loading
//so that the video page will not error out if you refresh on it
const fallbackContent = {
  cme: "0",
  contact_hour: "0",
  category_name: "",
  lesson_number: "RL-100",
  content_for: [],
  title: "",
  tagline: "",
  description: "",
  type: "Video",
  content_status: "",
  application: "",
  reference_1_url: null,
  date_created: "",
  date_modified: "",
  deeper_1: "",
  deeper_2: "",
  deeper_3: "",
  file_url: "",
  handout_title: null,
  image: "",
  last_modified_by: "",
  length: "1:23",
  notes: "",
  notes_1: null,
  notes_2: null,
  notes_3: null,
  objective_1: "",
  objective_2: "",
  objective_3: null,
  recommended_reading_1: null,
  recommended_reading_1_doc: null,
  web_url_recommended_reading_1: null,
  recommended_reading_2: null,
  recommended_reading_2_doc: null,
  web_url_recommended_reading_2: null,
  recommended_reading_3: null,
  recommended_reading_3_doc: null,
  web_url_recommended_reading_3: null,
  record_id_: "",
  record_owner: "",
  references: null,
  related_category: null,
  wider_1: "",
  wider_2: "",
  wider_3: "",
  update_id: ""
};

export default function enrichment_center(state = initialState, action) {
  switch (action.type) {
    case FETCHING_EC_CONTENT:
      return Object.assign({}, state, { fetchingEcContent: action.status });
    case EC_CONTENT_FETCH_SUCCESS:
      return Object.assign({}, state, {
        content: action.data,
        totalContent: action.totalContent,
        ecContentFetchError: null
      });
    case EC_CONTENT_FETCH_FAILURE:
      return Object.assign({}, state, { ecContentFetchError: action.error });
    case FETCHING_EC_SECTIONS:
      return Object.assign({}, state, { fetchingEcSections: action.status });
    case EC_SECTIONS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        sections: action.data,
        ecSectionsFetchError: null
      });
    case EC_SECTIONS_FETCH_FAILURE:
      return Object.assign({}, state, { ecSectionsFetchError: action.error });
    case FETCHING_EC_CONTENT_TO_SECTIONS:
      return Object.assign({}, state, { fetchingEcContentToSections: action.status });
    case EC_CONTENT_TO_SECTIONS_FETCH_SUCCESS:
      return Object.assign({}, state, {
        contentToSections: action.data,
        ecContentToSectionsFetchError: null
      });
    case EC_CONTENT_TO_SECTIONS_FETCH_FAILURE:
      return Object.assign({}, state, { ecContentToSectionsFetchError: action.error });
    case FETCHING_EC_CATEGORIES:
      return Object.assign({}, state, { fetchingEcCategories: action.status });
    case EC_CATEGORIES_FETCH_SUCCESS:
      return Object.assign({}, state, {
        categories: action.data,
        ecCategoriesFetchError: null
      });
    case EC_CATEGORIES_FETCH_FAILURE:
      return Object.assign({}, state, { ecCategoriesFetchError: action.error });
    case FETCHING_CME_CREDIT:
      return Object.assign({}, state, { fetchingCmeCredits: action.status });
    case CME_CREDIT_FETCH_SUCCESS:
      return Object.assign({}, state, { claimedContent: action.data, cmeCreditFetchError: null });
    case CME_CREDIT_FETCH_FAILURE:
      return Object.assign({}, state, { cmeCreditFetchError: action.error });
    case CLAIMING_CME_CREDIT:
      return Object.assign({}, state, { claimingCredit: action.status });
    case CLAIM_CME_CREDIT_SUCCESS:
      return Object.assign({},
        state,
        {
          claimCreditError: null,
          claimedContent: state.claimedContent.concat([action.data])
        });
    case CLAIM_CME_CREDIT_FAILURE:
      return Object.assign({}, state, { claimCreditError: action.error });
    case FETCHING_CONTACT_HOURS:
      return Object.assign({}, state, { fetchingCreditHours: action.data });
    case CONTACT_HOURS_FETCH_SUCCESS:
      return Object.assign({}, state, { claimedContactHourContent: action.data, contactHoursFetchError: null });
    case CONTACT_HOURS_FETCH_FAILURE:
      return Object.assign({}, state, { contactHoursFetchError: action.error });
    case CLAIMING_CONTACT_HOURS:
      return Object.assign({}, state, { claimingContactHour: action.status });
    case CLAIM_CONTACT_HOURS_SUCCESS:
      return Object.assign({},
        state,
        {
          claimContactHourError: null,
          claimedContactHourContent: state.claimedContactHourContent.concat([action.data])
        });
    case CLAIM_CONTACT_HOURS_FAILURE:
      return Object.assign({}, state, { claimContactHourError: action.error });
    case TIME_WATCHED_FETCH_SUCCESS:
      return Object.assign({}, state, { timeWatched: action.data })
    case TIME_WATCHED_FETCH_FAILURE:
      return Object.assign({}, state, { timeWatchedError: action.error })
    case FETCHING_TIME_WATCHED:
      return Object.assign({}, state, { fetchingTimeWatched: action.status })
    case UPDATE_TIME_WATCHED:
      const newTimeWatched = Object.assign({}, state.timeWatched, action.updatedTime)
      return Object.assign({}, state, { timeWatched: newTimeWatched })
    case UPDATE_TOAST_NOTIFICATION:
      return Object.assign({}, state, { toastNotificationStatus: action.status, toastNotificationData: action.data });
    case SET_SORT_VALUE:
      return Object.assign({}, state, { sortValue: action.value });
    default:
      return state;
  }
}

// returns a js object where each attribute is a category (string) and each value is array of the ec content that had that category
export const contentByCategory = (enrichment_center) => {
  const { categories, content } = enrichment_center;
  const categorizedContent = categories.reduce((ret, curr) => { ret[curr.category_name] = []; return ret; }, {});
  for (let i of content) {
    if (categorizedContent[i.category]) categorizedContent[i.category].push(i);
  }
  return categorizedContent;
};

export const getContentBySection = (enrichment_center, section) => {
  const { content, contentToSections } = enrichment_center;
  const contentIds = new Set(contentToSections.filter(cts => cts.related_section === section).map(cts => cts.related_content));
  return content.filter(c => contentIds.has(c.record_id_));
}

export const getContentRecordId = (enrichment_center, section, lesson_number) => {
  const content = getContentBySection(enrichment_center, section);
  const contentLoaded = content.length > 0;
  const main = contentLoaded ? content.find(c =>
    c.lesson_number === lesson_number &&
    c.type.toLowerCase() !== 'handout'
  ) : fallbackContent;
  return main.record_id_;
}

export const getContentByRecordId = (enrichment_center, record_id) => {
  const { content } = enrichment_center;
  return content.find((c) => c.record_id_ === record_id || Number(c.record_id_) === record_id);
}

export const getContentAndDeeperWider = (enrichment_center, record_id) => {
  const { content } = enrichment_center;
  // check if the ec content has loaded from s3

  const contentLoaded = content.length > 0;
  const main = contentLoaded ? content.find(c =>
    (c.record_id_ === record_id || Number(c.record_id_) === record_id) &&
    c.type.toLowerCase() !== 'handout'
  ) : fallbackContent;
  const handout = contentLoaded && main ? content.find(c =>
    c.lesson_number === main.lesson_number &&
    c.type.toLowerCase() === 'handout'
  ) : fallbackContent;
  const deeper = contentLoaded && main ? content.filter(c =>
    [main.deeper_1, main.deeper_2, main.deeper_3].includes(c.lesson_number) &&
    c.type.toLowerCase() !== 'handout'
  ).sort((a, b) => parseInt(a.order) - parseInt(b.order)) : [];
  const wider = contentLoaded && main ? content.filter(c =>
    [main.wider_1, main.wider_2, main.wider_3].includes(c.lesson_number) &&
    c.type.toLowerCase() !== 'handout'
  ).sort((a, b) => parseInt(a.order) - parseInt(b.order)) : [];

  // console.log(main);
  return {
    main,
    handout,
    deeper,
    wider
  }
};

// This functions checks to see if the current user has already claimed credit
// for given content. if not, return "unclaimed", and if so return cme_certificate_awarded_at
// unless if cme_certificate_awarded_at is null, then return "pending" instead
export const getCmeCreditStatus = (enrichment_center, lesson_number) => {
  const { claimedContent } = enrichment_center;
  let status = "unclaimed";
  if (!claimedContent) return status;

  const filtered = claimedContent.find(c => c.lesson_number === lesson_number);
  //TODO check if "cme_certificate_awarded_at" old enough to reissue CME Credit
  if (filtered) status = filtered["cme_certificate_awarded_at"] ? filtered["cme_certificate_awarded_at"] : "pending";
  return status
};

export const getContactHourStatus = (enrichment_center, lesson_number) => {
  const { claimedContactHourContent } = enrichment_center;
  let status = "unclaimed";
  if (!claimedContactHourContent) return status;

  const filtered = claimedContactHourContent.find(c => c.lesson_number === lesson_number);
  if (filtered) status = filtered["contact_hours_awarded_at"] ? filtered["contact_hours_awarded_at"] : "pending";
  return status
};


// pull dates of when given cme credit was claimed in the past
export const getClaimedCmeCreditDates = (enrichment_center, lesson_number) => {
  const { claimedContent } = enrichment_center;
  if (!claimedContent) return [];
  const dates = claimedContent.filter(c => c.lesson_number === lesson_number).map(c => c["created_at"]);
  return dates;
};

// pull dates of when given contact hour was claimed in the past
export const getClaimedContactHourDates = (enrichment_center, lesson_number) => {
  const { claimedContactHourContent } = enrichment_center;
  if (!claimedContactHourContent) return [];
  const dates = claimedContactHourContent.filter(c => c.lesson_number === lesson_number).map(c => c["created_at"]);
  return dates;
};

export const getSectionName = (enrichment_center, section) => {
  const { sections } = enrichment_center;
  const result = sections.find(s => s.record_id_ === section);
  return result && result.section_name ? result.section_name : "";
}

export const getSectionCreditDisclaimer = (enrichment_center, section) => {
  const { sections } = enrichment_center;
  const result = sections.find(s => s.record_id_ === section);
  return result && result.credit_disclaimer ? result.credit_disclaimer : "";
}

export const getSectionByRecordId = (enrichment_center, record_id_) => {
  const object = enrichment_center.contentToSections?.find(cts => {
      return cts.content___record_id_ === record_id_;
  })
  return object?.related_section
}

export const getSectionNameByRecordId = (enrichment_center, record_id_) => {
  const object = enrichment_center.contentToSections?.find(cts => {
    return cts.content___record_id_ === record_id_ || Number(cts.content___record_id_) === record_id_;
  })
  return object?.section_name
}

export const getCategoryByRecordId = (contentToSections, record_id_) => {}