// for the ANA filter
export const yearsInNursing = ["All","< 2 years","2-4 years","5-9 years","10-19 years", "20+ years"];
export const yearsInNursingWyoming = ["All", "1-2 years", "2-7 years", "8-13 years", "14+ years"];

const today = new Date();
const month = today.getMonth()+1;
let day = today.getDate();
if (month === 2 && day === 29) {
  day = 28;
}

const userOptions = {
  "gender": {
    "Male (including transgender male)": "M",
    "Female (including transgender female)": "F",
    "Non-binary": "B",
    "Non-conforming": "C",
    "Prefer not to answer": "P"
  },
  "burnout_specialty": {
    "Allergy and Immunology": "Allergy and Immunology",
    "Alternative Medicine": "Alternative Medicine",
    "Anesthesiology": "Anesthesiology",
    "Cardiology": "Cardiology",
    "Cardiothoracic Surgery": "Cardiothoracic Surgery",
    "Colorectal Surgery": "Colorectal Surgery",
    "Comparative Medicine": "Comparative Medicine",
    "Cosmetic Surgery": "Cosmetic Surgery",
    "Dentistry": "Dentistry",
    "Dermatology": "Dermatology",
    "Emergency Medicine": "Emergency Medicine",
    "Endocrinology": "Endocrinology",
    "Family Medicine": "Family Medicine",
    "Gastroenterology and Hepatology": "Gastroenterology and Hepatology",
    "General Internal Medicine": "General Internal Medicine",
    "General Pediatrics": "General Pediatrics",
    "General Surgery": "General Surgery",
    "Genetics": "Genetics",
    "Geriatric Medicine": "Geriatric Medicine",
    "Hematology": "Hematology",
    "Hospital Medicine": "Hospital Medicine",
    "Infectious Disease": "Infectious Disease",
    "Intensive Care Medicine": "Intensive Care Medicine",
    "Microbiology and Immunology": "Microbiology and Immunology",
    "Nephrology": "Nephrology",
    "Neurology": "Neurology",
    "Neuroscience":"Neuroscience",
    "Neurosurgery": "Neurosurgery",
    "Obstetrics and Gynecology": "Obstetrics and Gynecology",
    "Occupational Medicine": "Occupational Medicine",
    "Oncology": "Oncology",
    "Optometry": "Optometry",
    "Ophthalmology": "Ophthalmology",
    "Orthopedics": "Orthopedics",
    "Otolaryngology": "Otolaryngology",
    "Otorhinolaryngology": "Otorhinolaryngology",
    "Pain Medicine": "Pain Medicine",
    "Palliative Care": "Palliative Care",
    "Pathology": "Pathology",
    "Pharmacology": "Pharmacology",
    "Physical Medicine": "Physical Medicine",
    "Plastic Surgery": "Plastic Surgery",
    "Podiatry": "Podiatry",
    "Preventative Medicine": "Preventative Medicine",
    "Primary Care": "Primary Care",
    "Psychiatry": "Psychiatry",
    "Pulmonology": "Pulmonology",
    "Radiation Oncology":"Radiation Oncology",
    "Radiology": "Radiology",
    "Reproductive Endocrinology": "Reproductive Endocrinology",
    "Rheumatology": "Rheumatology",
    "Sleep Medicine": "Sleep Medicine",
    "Sports Medicine": "Sports Medicine",
    "Toxicology": "Toxicology",
    "Transplant Surgery": "Transplant Surgery",
    "Trauma Surgery": "Trauma Surgery",
    "Urology": "Urology",
    "Vascular Surgery": "Vascular Surgery",
    "Wound Medicine": "Wound Medicine",
  },
  "feedback": {
    '': '',
    "Strongly Agree": "Strongly Agree",
    "Agree": "Agree",
    "Neutral": "Neutral",
    "Disagree": "Disagree",
    "Strongly Disagree": "Strongly Disagree",
  },

  "license_type": {
    "Physician":"Physician",
    "Nurse": "Nurse",
    "Certified Nurse Midwife":"Certified Nurse Midwife",
    "Certified Registered Nurse Anesthetist":"Certified Registered Nurse Anesthetist",
    "Certified Registered Nurse Practitioner":"Certified Registered Nurse Practitioner",
    "Dentist":"Dentist",
    // "Doctor of Optometry":"Doctor of Optometry",
    // "Doctor of Osteopathic Medicine":"Doctor of Osteopathic Medicine",
    "PhD / other doctoral degree":"PhD / other doctoral degree",
    // "Doctor of Podiatric Medicine":"Doctor of Podiatric Medicine",
    "EEG Technician":"EEG Technician",
    // "Licensed Practical Nurse":"Licensed Practical Nurse",
    "Nursing Assistant (CNA, PCT)":"Nursing Assistant (CNA, PCT)",
    "Occupational Therapist":"Occupational Therapist",
    "Optometrist": "Optometrist",
    "Pharmacist":"Pharmacist",
    "Pharmacy Technician":"Pharmacy Technician",
    "Physical Therapist":"Physical Therapist",
    "Physician Assistant":"Physician Assistant",
    "Radiology Technician":"Radiology Technician",
    "Registered Dietitian":"Registered Dietitian",
    // "Registered Nurse":"Registered Nurse",
    "Respiratory Therapist":"Respiratory Therapist",
    "Speech Therapist":"Speech Therapist",
    "Surgical Technician":"Surgical Technician",
  },
  // "age_range": {
  //   "Under 28": createDate(0, 0, -18),
  //   "28 - 34": createDate(0, 0, -28),
  //   "35 - 44": createDate(0, 0, -35),
  //   "45 - 54": createDate(0, 0, -45),
  //   "55 - 69": createDate(0, 0, -55),
  //   "Over 69": createDate(0, 0, -70),
  // },
  "age_range": {
    "Under 28": (month ) + '/' + (day) + '/' + (today.getFullYear() - 24),
    "28 - 34": (month ) + '/' + (day) + '/' + (today.getFullYear() - 28),
    "35 - 44": (month ) + '/' + (day) + '/' + (today.getFullYear() - 35),
    "45 - 54": (month ) + '/' + (day) + '/' + (today.getFullYear() - 45),
    "55 - 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 55),
    "Over 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 70),
  }
};

export const anaUserOptions = {
  "gender": {
    "Male (including transgender male)": "M",
    "Female (including transgender female)": "F",
    "Non-binary": "B",
    "Non-conforming": "C",
    "Prefer not to answer": "P"
  },
  "burnout_specialty": {
    "< 2 years": "< 2 years",
    "2-4 years": "2-4 years",
    "5-9 years": "5-9 years",
    "10-19 years": "10-19 years",
    "20+ years": "20+ years",
  },
  "feedback": {
    '': '',
    "Strongly Agree": "Strongly Agree",
    "Agree": "Agree",
    "Neutral": "Neutral",
    "Disagree": "Disagree",
    "Strongly Disagree": "Strongly Disagree",
  },
  "burnout_location": {
    "Acute Care/Hospital": "Acute Care/Hospital",
    "Ambulatory/Community Health": "Ambulatory/Community Health",
    "Education/School of Nursing": "Education/School of Nursing",
    "Long Term Care/Skilled Nursing Facility/Rehabilitation": "Long Term Care/Skilled Nursing Facility/Rehabilitation",
    "Other": "Other",
    "Not employed in nursing": "Not employed in nursing"
  },
  "license_type": {
    "Clinical Nurse/Staff Nurse": "Clinical Nurse/Staff Nurse",
    "Nurse Educator or Professor": "Nurse Educator or Professor",
    "Advance Practice Nurse (NP, CNS, CNM, CRNA)": "Advance Practice Nurse",
    "Nurse Manager": "Nurse Manager",
    "Executive/Director/CNO/ACNO": "Executive/Director/CNO/ACNO",
    "Other position in nursing": "Other position in nursing",
    "Unemployed": "Unemployed",
    "Retired": "Retired"
  },
  // "age_range": {
  //   "Under 28": createDate(0, 0, -18),
  //   "28 - 34": createDate(0, 0, -28),
  //   "35 - 44": createDate(0, 0, -35),
  //   "45 - 54": createDate(0, 0, -45),
  //   "55 - 69": createDate(0, 0, -55),
  //   "Over 69": createDate(0, 0, -70),
  // },
  "age_range": {
    "Under 28": (month ) + '/' + (day) + '/' + (today.getFullYear() - 24),
    "28 - 34": (month ) + '/' + (day) + '/' + (today.getFullYear() - 28),
    "35 - 44": (month ) + '/' + (day) + '/' + (today.getFullYear() - 35),
    "45 - 54": (month ) + '/' + (day) + '/' + (today.getFullYear() - 45),
    "55 - 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 55),
    "Over 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 70),
  }
};

export const chamberlainUserOptions = {
  "gender": {
    "Male (including transgender male)": "M",
    "Female (including transgender female)": "F",
    "Non-binary": "B",
    "Non-conforming": "C",
    "Prefer not to answer": "P"
  },
  "burnout_specialty": {
    "< 2 years": "< 2 years",
    "2-4 years": "2-4 years",
    "5-9 years": "5-9 years",
    "10-19 years": "10-19 years",
    "20+ years": "20+ years",
  },
  "burnout_location": {
    "Acute Care": "Acute Care",
    "Outpatient/Ambulatory": "Outpatient/Ambulatory",
    "Long-term Care": "Long-term Care",
    "Hospice": "Hospice",
    "Home Health": "Home Health",
    "School Nurse": "School Nurse",
    "Other": "Other"
  },
  "license_type": {
    "Employed full-time": "Employed full-time",
    "Employed part-time": "Employed part-time",
    "Employed Per diem": "Employed Per diem",
    "Not Employed": "Not Employed",
    "Other": "Other"
  },
  "feedback": {
    '': '',
    "Strongly Agree": "Strongly Agree",
    "Agree": "Agree",
    "Neutral": "Neutral",
    "Disagree": "Disagree",
    "Strongly Disagree": "Strongly Disagree",
  },
  "age_range": {
    "Under 28": (month ) + '/' + (day) + '/' + (today.getFullYear() - 24),
    "28 - 34": (month ) + '/' + (day) + '/' + (today.getFullYear() - 28),
    "35 - 44": (month ) + '/' + (day) + '/' + (today.getFullYear() - 35),
    "45 - 54": (month ) + '/' + (day) + '/' + (today.getFullYear() - 45),
    "55 - 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 55),
    "Over 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 70),
  }
}

export const cppUserOptions = {
  "gender": {
    "Male (including transgender male)": "M",
    "Female (including transgender female)": "F",
    "Non-binary": "B",
    "Non-conforming": "C",
    "Prefer not to answer": "P"
  },
  "feedback": {
    '': '',
    "Strongly Agree": "Strongly Agree",
    "Agree": "Agree",
    "Neutral": "Neutral",
    "Disagree": "Disagree",
    "Strongly Disagree": "Strongly Disagree",
  },
  "age_range": {
    "Under 28": (month ) + '/' + (day) + '/' + (today.getFullYear() - 24),
    "28 - 34": (month ) + '/' + (day) + '/' + (today.getFullYear() - 28),
    "35 - 44": (month ) + '/' + (day) + '/' + (today.getFullYear() - 35),
    "45 - 54": (month ) + '/' + (day) + '/' + (today.getFullYear() - 45),
    "55 - 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 55),
    "Over 69": (month ) + '/' + (day) + '/' + (today.getFullYear() - 70),
  },
  "burnout_specialty": {
    "Allergy and Immunology": "Allergy and Immunology",
    "Alternative Medicine": "Alternative Medicine",
    "Anesthesiology": "Anesthesiology",
    "Cardiology": "Cardiology",
    "Cardiothoracic Surgery": "Cardiothoracic Surgery",
    "Colorectal Surgery": "Colorectal Surgery",
    "Comparative Medicine": "Comparative Medicine",
    "Cosmetic Surgery": "Cosmetic Surgery",
    "Dentistry": "Dentistry",
    "Dermatology": "Dermatology",
    "Emergency Medicine": "Emergency Medicine",
    "Endocrinology": "Endocrinology",
    "Family Medicine": "Family Medicine",
    "Gastroenterology and Hepatology": "Gastroenterology and Hepatology",
    "General Internal Medicine": "General Internal Medicine",
    "General Pediatrics": "General Pediatrics",
    "General Surgery": "General Surgery",
    "Genetics": "Genetics",
    "Geriatric Medicine": "Geriatric Medicine",
    "Hematology": "Hematology",
    "Hospital Medicine": "Hospital Medicine",
    "Infectious Disease": "Infectious Disease",
    "Intensive Care Medicine": "Intensive Care Medicine",
    "Microbiology and Immunology": "Microbiology and Immunology",
    "Nephrology": "Nephrology",
    "Neurology": "Neurology",
    "Neuroscience":"Neuroscience",
    "Neurosurgery": "Neurosurgery",
    "Obstetrics and Gynecology": "Obstetrics and Gynecology",
    "Occupational Medicine": "Occupational Medicine",
    "Oncology": "Oncology",
    "Optometry": "Optometry",
    "Ophthalmology": "Ophthalmology",
    "Orthopedics": "Orthopedics",
    "Otolaryngology": "Otolaryngology",
    "Otorhinolaryngology": "Otorhinolaryngology",
    "Pain Medicine": "Pain Medicine",
    "Palliative Care": "Palliative Care",
    "Pathology": "Pathology",
    "Pharmacology": "Pharmacology",
    "Physical Medicine": "Physical Medicine",
    "Plastic Surgery": "Plastic Surgery",
    "Podiatry": "Podiatry",
    "Preventative Medicine": "Preventative Medicine",
    "Primary Care": "Primary Care",
    "Psychiatry": "Psychiatry",
    "Pulmonology": "Pulmonology",
    "Radiation Oncology":"Radiation Oncology",
    "Radiology": "Radiology",
    "Reproductive Endocrinology": "Reproductive Endocrinology",
    "Rheumatology": "Rheumatology",
    "Sleep Medicine": "Sleep Medicine",
    "Sports Medicine": "Sports Medicine",
    "Toxicology": "Toxicology",
    "Transplant Surgery": "Transplant Surgery",
    "Trauma Surgery": "Trauma Surgery",
    "Urology": "Urology",
    "Vascular Surgery": "Vascular Surgery",
    "Wound Medicine": "Wound Medicine",
  },
  "burnout_location": {

  },
  "license_type": {
    "Advanced Practice Registered Nursing (CRNA, CNM, CRNP)": "Advanced Practice Registered Nursing (CRNA, CNM, CRNP)",
    "Clinical Support (Med. Assist., Phleb. etc.)": "Clinical Support (Med. Assist., Phleb. etc.)",
    "Executive/Admin Director/Director": "Executive/Admin Director/Director",
    "Finance/Accounting/Med. Rec./Billing": "Finance/Accounting/Med. Rec./Billing",
    "Leadership (Manager/Supervisor/Charge/Lead)": "Leadership (Manager/Supervisor/Charge/Lead)",
    "Nurse (RN/LPN)": "Nurse (RN/LPN)",
    "Patient Rep (Intake/Registration/Scheduler)": "Patient Rep (Intake/Registration/Scheduler)",
    "Physician": "Physician",
    "Physician Assistant": "Physician Assistant",
    "Technician (e.g., Surg., Lab, EKG, Rad.)": "Technician (e.g., Surg., Lab, EKG, Rad.)",
    "Therapist (PT, OT, Respiratory, Speech, Music)": "Therapist (PT, OT, Respiratory, Speech, Music)"
  }
}

export default userOptions;
